// *----------------------------------**
//  Settings > Spaces
// *----------------------------------*/

// Space : Padding & Margin
$space-unit: 0.75rem !default; // 7.5px

$space-mega: max($space-unit * 6); // 45px
$space-kilo: max($space-unit * 5); // 37.5px
$space-hecto: max($space-unit * 4); // 30px
$space-deca: max($space-unit * 3); // 22.5px
$space-base: max($space-unit * 2); // 15px
$space-deci: 1rem; // 10px
$space-centi: $space-unit; // 7.5px

$gutter: $space-centi !default;

// *----------------------------------*/
//  Settings > Size
// *----------------------------------*/

// Width & Height
$width-container: 128rem !default; // 1280px
$width-aside: 28.4rem !default;
$width-full: 100% !default;
$height-full: 100% !default;

// Border
$border-base: 2px !default;

// Border Radius
$border-radius: 5px !default;
$border-radius-lg: 10px !default;
$border-radius-xl: 50px !default;

// *----------------------------------*/
//  Settings > Specific
// *----------------------------------*/

// Header height
$header-height: 58px !default;
$header-height-desktop: 75px !default;
$menu-height: 0 !default;
$menu-height-desktop: 58px !default;

$border-radius-btn: $border-radius-xl !default;
$padding-btn: 1.3rem $space-deca !default;
