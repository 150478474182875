.tab--profile {
  .ant-tabs-nav-list {
    border-left: solid 0.5px $color-grey-base;
    background-color: $color-white;
    min-height: calc(100vh - 100px);
  }
  .ant-tabs-tab-active {
    background-color: $color-primary;
    font-weight: $weight-bold;
    border-bottom: none;
    .ant-tabs-tab-btn {
      color: $color-white !important;
    }
    &.ant-tabs-tab {
      border-bottom: none;
    }
  }
  .ant-tabs-tab {
    margin-top: 0 !important;
    border-bottom: 0.5px solid $color-grey-base;
  }
}
