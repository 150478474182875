.service {
  background-color: #f0f0f0;
  padding: 0 0 $space-base;
  &--card {
    background-color: $grey-color-extra-extra;
    @media #{$breakpoint-sm-only}, #{$breakpoint-xs-only} {
      box-shadow: $shadow;
      margin: $space-base 0;
      .content {
        order: 1;
      }
      .image {
        height: 200px;
        .img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
    .last,
    .first {
      padding-left: 0;
      padding-right: 0;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 $space-base;
    }
  }
}

.partner {
  background-color: $color-white;
  &--count {
    background: linear-gradient(
        rgba($color: #000000, $alpha: 0.7),
        rgba($color: #000000, $alpha: 0.7)
      ),
      url("../../images//hospital.jpg");
    background-size: 100%;
    background-position: 0;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-title {
      .title {
        color: $gold-color;
        font-size: $font-size-giga;
        font-weight: $weight-bold;
        text-shadow: 5px 1px 4px #000;
      }
      .subtitle {
        font-size: $font-size-kilo;
        color: $color-white;
        font-weight: $weight-bold;
      }
    }
    &-text {
      .count {
        font-weight: $weight-bold;
        color: $color-white;
        font-size: $font-size-giga;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-bottom: $space-base;
      }
      .title,
      .plus {
        color: $color-white;
        font-weight: $weight-bold;
      }
    }
  }
  &--logo {
    width: 19.85rem;
    height: 17.715rem;
    img {
      width: 100%;
      object-fit: cover;
      display: block;
    }
    &--swiper {
      .swiper {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .swiper-slide {
        width: 80%;
      }

      .swiper-slide:nth-child(2n) {
        width: 60%;
      }

      .swiper-slide:nth-child(3n) {
        width: 40%;
      }
    }
  }
}

.review {
  &__card {
    background: #f7f7f7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    margin: $space-deca auto;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: $space-base;
    li {
      margin-bottom: 0;
    }
    .title {
      .ant-typography,
      & {
        font-size: $font-size-kilo;
        padding: $space-unit $space-base 0;
        margin-bottom: 0;
        font-weight: $weight-bold;
      }
    }
  }
}
