// *----------------------------------**
//  Settings > Type
// *----------------------------------*/

// Font family
$font-family: "Poppins", "Montserrat", "Helvetica Neue", "Helvetica", "Arial",
  sans-serif !default;

// Font size
$font-size-giga: 2.8rem !default;
$font-size-mega: 2.4rem !default;
$font-size-kilo: 2.2rem !default;
$font-size-hecto: 2rem !default;
$font-size-deca: 1.8rem !default;
$font-size-base: 1.6rem !default; // 16px
$font-size-deci: 1.4rem !default;
$font-size-centi: 1.2rem !default;

// Font weight
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-bold: 600 !default;

// Line height
$line-height-kilo: 1.7 !default;
$line-height-hecto: 1.43 !default;
$line-height-base: 1.2 !default;
$line-height-deci: 1 !default;
