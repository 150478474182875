// *----------------------------------**
//  Objects > Wrapper
// *----------------------------------*/

.wrapper {
  padding-top: $space-kilo;
  padding-bottom: $space-kilo;

  // Colours
  &--grey {
    background-color: $color-grey-nano;
  }

  &--primary {
    background-color: $color-primary;
    color: white;
  }

  // Sizes
  &--lg {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &--sm {
    padding-top: $space-deca;
    padding-bottom: $space-deca;
  }

  // Separator
  &--border-top {
    border-top: 0.8rem solid $color-secondary;
  }
}

// *----------------------------------**
//  Component > Wrapper Content
// *----------------------------------*/

.wrapper-content {
  width: 100%;
  padding: $space-hecto $space-base;
  margin-bottom: $space-hecto;

  @media #{$breakpoint-lg-up} {
    padding: $space-hecto;
  }

  &--grey {
    @extend .wrapper-content;
    background-color: $color-grey-nano;
  }

  &--primary {
    @extend .wrapper-content;
    background-color: $color-primary;
    color: $color-white;

    a {
      color: $color-white;
    }
  }

  &--sm {
    @extend .wrapper-content;
    padding: $space-base;
  }

  &--lg {
    @extend .wrapper-content;
    padding: $space-kilo;
  }
}
