// *----------------------------------**
//  Objects > Container
// *----------------------------------*/

.container {
  width: $width-full;
  max-width: $width-container;
  margin: auto;
  padding-left: $space-base;
  padding-right: $space-base;
  position: inherit;

  @media #{$breakpoint-lg-up} {
    padding-left: $space-hecto;
    padding-right: $space-hecto;
    width: 94%;
  }

  &--xs {
    @extend .container;
    max-width: 99rem;
  }

  &--sm {
    @extend .container;
    max-width: 114rem;
  }

  &--white {
    @extend .container;
    background-color: $color-white;
  }
  &--rubrique {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @media #{$breakpoint-lg} {
      padding: 0 $space-base;
    }
  }
}

// *----------------------------------*/
// Container Header
// After parallax--header

.container--header {
  @extend .container;
  padding-top: $space-hecto;
  background-color: $color-white;
  margin-top: 18rem;
}

// *----------------------------------*/
// Container multiple
// With Aside

.container--multiple {
  @extend .container;
  @include flexbox;
  @include flex-direction(column);

  @media #{$breakpoint-lg-up} {
    @include flex-direction(row);
  }

  .container__main {
    flex: 1;

    @media #{$breakpoint-lg-up} {
      &:not(:first-child) {
        padding-left: $space-hecto;
      }

      &:not(:last-child) {
        padding-right: $space-hecto;
      }
    }
  }

  aside,
  .aside {
    width: 100%;
    margin-bottom: $space-base;

    @media #{$breakpoint-lg-up} {
      width: 25.4rem;
    }
  }
}
