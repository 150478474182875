$menu-breakpoint: 832px;
$xs-width: 576px;
$sm-width: 676px;
$md-width: 768px;

$box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.0431373);

$grey-color: #636363;
$grey-color-dark: #2b2b2b;
$grey-color-light: #b2b2b2;
$grey-color-extra: #dfdfdf;
$grey-color-extra-extra: #f5f5f5;

$white-color: #ffffff;

$shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
$shadow-hover: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
