// *----------------------------------**
//  Tools > Generic animations
// *----------------------------------*/

@media #{$breakpoint-lg-up} {
  // Fade in
  @-webkit-keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  // Fade in up
  @-webkit-keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateY(0%);
    }
  }

  @-moz-keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateY(0%);
    }
  }

  @-o-keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateY(0%);
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateY(0%);
    }
  }

  .fadeIn.up {
    visibility: hidden;
    animation-duration: 1.5s;
    animation-name: none;
  }

  // Fade in down
  @-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateY(0%);
    }
  }

  @-moz-keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateY(0%);
    }
  }

  @-o-keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateY(0%);
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateY(0%);
    }
  }

  .fadeIn.down {
    visibility: hidden;
    animation-duration: 1.5s;
    animation-name: none;
  }

  // Fade in left
  @-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateX(0%);
    }
  }

  @-moz-keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateX(0%);
    }
  }

  @-o-keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateX(0%);
    }
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateX(0%);
    }
  }

  .fadeIn.left {
    visibility: hidden;
    animation-duration: 1.5s;
    animation-name: none;
  }

  // Fade in right
  @-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateX(0%);
    }
  }

  @-moz-keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateX(0%);
    }
  }

  @-o-keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateX(0%);
    }
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(100%);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateX(0%);
    }
  }

  .fadeIn.right {
    visibility: hidden;
    animation-duration: 1.5s;
    animation-name: none;
  }
}
