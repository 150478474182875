.message {
  &--expeditors {
    width: 100%;
    box-shadow: $shadow;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: $space-unit;
    }
    &::-webkit-scrollbar-track {
      background: linear-gradient($grey-color-light, $color-xlight);
      box-shadow: $shadow;
      border-radius: 0 0 15px;
    }
    &::-webkit-scrollbar-thumb {
      background: linear-gradient($color-black, #555);
      box-shadow: $shadow;
      border-radius: 15px;
      cursor: pointer;
      &:hover {
        box-shadow: $shadow-hover;
      }
    }
    &:hover {
      box-shadow: $shadow-hover;
    }
    .ant-list-items {
      margin-left: 0;
    }
    &-item {
      width: 100%;
      cursor: pointer;
      margin-bottom: 0;
      &:hover {
        background-color: $color-primary;
        color: $color-white !important;
      }
      .content {
        width: 100%;
      }
      &.message {
        width: 100%;
      }
    }
  }
  &--content {
    &-item {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      &.exp {
        align-items: flex-end;
        .content {
          background-color: $color-danger;
        }
      }
      &.dest {
        align-items: flex-start;
        .content {
          background-color: $color-primary;
        }
      }
      .content {
        background-color: $color-primary;
        border-radius: 1.5rem;
        max-width: 90%;
        box-shadow: $shadow;
        .ant-typography {
          color: $color-white;
          padding: $space-unit;
          text-align: justify;
        }
      }
    }
  }
  &--list {
    max-height: 100vh;
    overflow-y: auto;
    border-top: solid 0.2px rgba($color: $color-black, $alpha: 0.1);
    border-radius: 15px;
    &::-webkit-scrollbar {
      width: $space-unit;
    }
    &::-webkit-scrollbar-track {
      background: linear-gradient($grey-color-light, $color-xlight);
      box-shadow: $shadow;
    }
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(
        $color-primary,
        $color-secondary,
        $color-danger
      );
      box-shadow: $shadow;
      border-radius: 15px;
      cursor: pointer;
      &:hover {
        box-shadow: $shadow-hover;
      }
    }
  }
}
