.heading {
  &--container {
    text-align: center;
    padding: $space-base;
    .separator--small {
      width: 80px;
      display: inline-block;
      border-bottom: 1px $color-black solid;
    }
  }
  &.ant-typography {
    text-align: center;
    margin-bottom: $space-unit;
  }
}
