// *----------------------------------*\
//  Settings > Colors
// *----------------------------------*/

// Base
$color-primary-light: #a99dad !default;
$color-primary: #14b4f0 !default;
$color-primary-dark: #705c78 !default;
$color-secondary-light: #fbd899 !default;
$color-secondary: #e6952c !default;
$color-secondary-dark: #de8919 !default;
$color-business: #7e675a !default;

$color-extra: #1885b8 !default;
$color-success: #8abc41 !default;
$color-warning: #ffba00 !default;
$color-danger: #ed1c24 !default;
$color-blue: #14b4f0;

$color-white: #ffffff !default;
$color-black: #000000 !default;

$grey-dark: #595959 !default;
$grey-medium: #bec3cd !default;
$grey-light: #f3f4f5 !default;
$gold-color: #fff763;

// Greys
$color-grey-base: #999999 !default;

$color-grey-giga: darken($color-grey-base, 50%) !default; // #1a1a1a
$color-grey-mega: darken($color-grey-base, 40%) !default; // #333333
$color-grey-kilo: darken($color-grey-base, 30%) !default; // #4d4d4d
$color-grey-hecto: darken($color-grey-base, 20%) !default; // #666666
$color-grey-deca: darken($color-grey-base, 10%) !default; // #808080

$color-grey-deci: lighten($color-grey-base, 10%) !default; // #b3b3b3
$color-grey-centi: lighten($color-grey-base, 20%) !default; // #cccccc
$color-grey-milli: lighten($color-grey-base, 25%) !default; // #d9d9d9
$color-grey-micro: lighten($color-grey-base, 30%) !default; // #e6e6e6
$color-grey-nano: lighten($color-grey-base, 35%) !default; // #f2f2f2

$color-grey-clear: rgba(0, 0, 0, 0.5) !default;

//color
$barre-contact-white: $color-white !default;

/*----------------------------------*/
// Specific

$color-text: #636363 !default;
$color-text-2: $grey-dark !default;

$color-placeholder: $grey-dark;
//$color-border-field:        $grey-medium;
$color-border-field: #705c78 !default;
$color-disabled: $grey-medium;
$grey-popup: rgba(5, 5, 5, 0.7);
$c-bg-footer: $color-grey-mega;

$bg-color-light: $color-grey-nano;
$color-xlight: $color-grey-nano;

// ecommerce
$color-shadedbox-separator: $grey-light;
$color-selected: $color-secondary;

$color-step-validate: $color-primary;
$color-step-current: $grey-light;
$color-step-progress: $color-secondary;
$color-step-disabled: $grey-light;
$color-step-text-validate: $color-primary;
$color-step-text-current: $color-primary;
$color-step-text-disabled: $grey-medium;

$header-sva-color: #91919b;
$grey-contact: #444;
