.decorate {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: $space-unit;
  .item {
    flex: 1;
    height: 0.2rem;
    //background: linear-gradient(to right top, $color-danger, gold, blue) no-repeat;
    transition: backround 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    //animation: decorate 15.5s infinite;
    &:first-child {
      background-color: $color-danger;
    }
    &:nth-child(2) {
      background-color: $gold-color;
    }
    &:nth-child(3) {
      background-color: $color-blue;
    }
  }
}
@keyframes decorate {
  0% {
    background: linear-gradient(
        to right top,
        $color-danger,
        $gold-color,
        $color-blue
      )
      no-repeat;
  }
  50% {
    background: linear-gradient(
      to left bottom,
      $color-danger,
      $gold-color,
      $color-blue
    );
  }
  100% {
    background: linear-gradient(
        to left top,
        $color-danger,
        $gold-color,
        $color-blue
      )
      no-repeat;
  }
}

.header {
  padding: 0 $space-mega;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: $color-white;
  &.h-box {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: rgba($color: $color-white, $alpha: 0.76);
    @media #{$breakpoint-xs-only},#{$breakpoint-sm-only} {
      background-color: rgba($color: $color-white, $alpha: 1);
    }
  }
  .logo {
    padding: 0 $space-base;
    img {
      height: 60px;
    }
  }
  .login {
    padding: 0 $space-base;
  }
  .space {
    flex: 1;
  }
  .nav {
    padding: 0 $space-unit;
    .icon {
      font-size: $font-size-giga;
    }
    @media #{$breakpoint-xs-only},#{$breakpoint-sm-only} {
      display: none;
    }
  }
}

.main {
  min-height: calc(100vh - 6rem);
}

.nav {
  &--link {
    margin-bottom: 0;
    padding: 0 $space-unit;
    a {
      color: $color-black;
      font-size: $font-size-deci;
      transition: color 1.1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      &:hover {
        color: $color-primary;
        font-weight: $weight-bold;
      }
      &.active {
        color: $color-primary;
        font-weight: $weight-bold;
      }
    }
  }
  &--list {
    padding-left: 0;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  &--icon {
    display: none;
    @media #{$breakpoint-xs-only},#{$breakpoint-sm-only} {
      display: block;
    }
  }
}

.sidebar-nav {
  position: fixed;
  width: 200px;
  right: 0;
  top: 60px;
  background-color: $color-white;
  //box-shadow: $shadow-hover;
  padding-top: $space-deca;
  display: none;
  height: calc(100vh - 120px);
  width: 100%;
  z-index: 3;
  .close {
    position: absolute;
    z-index: 4;
    left: 10px;
    top: 10px;
  }
  &.open {
    display: block;
  }
  .nav {
    &--list {
      flex-direction: column;
      .nav--link {
        width: 100%;
        padding: $space-unit 0 $space-unit;
      }
    }
  }
}
