.testmony {
  &__card {
    margin: auto;
    max-width: 982px;
    padding: $space-hecto;
    &-image {
      width: 250px;
      max-height: 250px;
      border-radius: $space-base;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $space-base;
        display: block;
      }
    }
    .comment {
      font-weight: $weight-normal;
      font-size: $font-size-hecto;
      padding-bottom: 6rem;
      text-align: justify;
    }
    .job {
      font-weight: $weight-normal;
      font-size: $font-size-hecto;
      @media #{$breakpoint-xs-only} {
        font-size: $font-size-base;
      }
    }
    .name {
      font-weight: $weight-bold;
      font-size: $font-size-hecto;
      @media #{$breakpoint-xs-only} {
        font-size: $font-size-base;
      }
    }
  }
}
