.card {
  &--book {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: $shadow;
    text-decoration: none;
    color: $color-black;
    margin: calc($space-unit / 2);
    height: 100%;
    border-radius: 15px;
    padding: $space-unit;
    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .title {
      text-align: center;
      padding: $space-unit;
    }
    .icon {
      font-size: 6.4rem;
      text-align: center;
      display: block;
    }
    .video {
      width: 100%;
    }
  }
  &--stretch {
    align-items: stretch;
    .card--person {
      height: 100%;
      .content {
        height: 100%;
        .row {
          height: 100%;
          .text {
            height: 100%;
            display: flex;
            flex-direction: column;
            .title {
              flex: 1;
            }
          }
        }
      }
    }
    .item {
      margin-bottom: $space-unit;
    }
  }
  &--person {
    background-color: $color-white;
    display: block;
    width: 100%;
    box-shadow: $shadow;
    text-decoration: none;
    color: $color-black;
    margin: calc($space-unit / 2);
    a {
      cursor: pointer;
    }
    &:hover {
      box-shadow: $shadow-hover;
    }
    .text {
      width: 100%;
    }
    .content {
      padding: $space-unit;
      &.border {
        border-bottom: 0.2rem solid transparent;
        &--primary {
          border-bottom-color: $color-primary;
        }
        &--danger {
          border-bottom-color: $color-danger;
        }
        &--gold {
          border-bottom-color: $color-secondary;
        }
      }
    }
    .title {
      font-size: $font-size-deca;
      padding: $space-base $space-unit $space-unit;
      display: block;
      font-weight: $weight-bold;
      word-wrap: break-word;
      width: 100%;
    }
    .subtitle {
      display: block;
      padding: 0 $space-unit;
      font-size: $font-size-centi;
    }
    .row {
      align-items: center;
    }
  }
}

.list {
  &--stretch {
    align-items: stretch;
    .ant-col,
    .ant-list-item {
      height: 100%;
    }
    .ant-col {
      padding-bottom: $space-unit;
    }
  }
}

.transfer {
  .ant-transfer-list-content-item {
    margin-bottom: 0;
  }
}
