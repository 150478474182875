// Creds to https://github.com/kristoferjoseph/flexboxgrid
// No gutters
// No text alignment with justified container classes (center-xs, for example)

// *----------------------------------**
//  Object > Grid
// *----------------------------------*/

.row {
  @include row();

  &--items-end {
    @include align-items(flex-end);
  }

  &--between {
    @include flexbox;
    @include justify-content(space-between);
  }

  &--full {
    @include row();
    width: auto;
    margin-right: -#{$space-centi};
    margin-left: -#{$space-centi};
  }
}

.row.reverse {
  @include row-reverse();
}

.row.natural-height {
  @include row-natural-height();
}

.row.flex-column {
  @include row-flex-column();
}

.col {
  @include col();
}

.col.reverse {
  @include col-reverse();
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

.justify-end {
  @include justify-content(flex-end);
}

@for $i from 1 through length($breakpoint-up-prefixes) {
  $thisPrefix: nth($breakpoint-up-prefixes, $i);
  @if $thisPrefix == "xs" {
    @include col-factory($thisPrefix);
  } @else if $thisPrefix == "sm" {
    @media #{$breakpoint-sm-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == "md" {
    @media #{$breakpoint-md-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == "lg" {
    @media #{$breakpoint-lg-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == "xl" {
    @media #{$breakpoint-xl-up} {
      @include col-factory($thisPrefix);
    }
  }
}

.col-gutter-lg {
  padding: 0 $space-base;
}

.col-no-gutter {
  padding: 0;
}
