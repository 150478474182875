.checkbox {
  &--group {
    width: 100%;
    max-width: 100%;
    .ant-checkbox-group {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.radio {
  .ant-typography {
    color: $color-black;
    font-weight: $weight-normal;
  }
}
