.footer {
  background-color: $color-danger;
  padding: $space-unit;
  .title {
    color: $color-white;
    text-align: center;
    display: inline-block;
    width: 100%;
  }
}
