.mockup {
  height: 100vh;
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &--content {
    max-width: 45rem;
    width: auto;
    margin: calc($space-mega * 2);
    .title {
      &.ant-typography {
        font-size: $font-size-giga;
      }
      @media #{$breakpoint-xs-only},#{$breakpoint-sm-only} {
        text-align: center;
      }
    }
    .text {
      font-size: $font-size-deca;
      @media #{$breakpoint-xs-only},#{$breakpoint-sm-only} {
        text-align: center;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
