// *----------------------------------**
//  Elements > HTML
// *----------------------------------*/

html {
  font-family: $font-family;
  font-weight: $weight-normal;
  font-size: 62.5%; // REM init
}

.ant-typography {
  font-family: $font-family;
}

body {
  font-size: $font-size-base;
  color: $color-text;
  position: relative;
  overflow: auto;
  padding-top: 0;
}

main {
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  /*margin-top: $header-height;

    @media #{$breakpoint-lg-up} {
        margin-top: $header-height-desktop;
    }*/
}

address {
  font-style: normal;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

a {
  color: $color-primary;
}

strong {
  font-weight: $weight-bold;
}

img {
  width: 100%;
  height: auto;
}

p {
  line-height: $line-height-hecto;
  width: 100%;
  display: block;
  margin-bottom: $space-base;
}

h2,
h3,
h4 {
  color: $color-primary;
  margin-bottom: $space-base;

  strong {
    font-weight: $weight-bold;
  }
}

h2,
h3 {
  font-size: $font-size-hecto;
  font-weight: $weight-bold;

  @media #{$breakpoint-lg-up} {
    font-size: $font-size-mega;
  }
}

h4 {
  font-size: $font-size-deca;
  font-weight: $weight-bold;

  @media #{$breakpoint-lg-up} {
    font-size: $font-size-deca;
  }
}

ol,
ul {
  list-style: none;
}

ul {
  padding-left: $space-hecto;

  li {
    margin-bottom: $space-base;

    /*&:before {
            content: '\f061';
            font: var(--fa-font-solid);
            color: $color-secondary;
            margin-right: $space-centi;
        }*/
  }
}

hr {
  height: 1px;
  margin: $space-centi 0;
  border: 0;
  border-top: 1px solid $color-grey-base;
}

:focus-visible {
  outline: none;
}

input {
  border-radius: 0;
  -webkit-appearance: none;
  &::-webkit-datetime-edit-month-field {
    color: $color-placeholder;
    font-weight: $weight-normal;
  }
  &::-webkit-datetime-edit-day-field {
    color: $color-placeholder;
    font-weight: $weight-normal;
  }
  &::-webkit-datetime-edit-year-field {
    color: $color-placeholder;
    font-weight: $weight-normal;
  }
  &::-webkit-datetime-edit-text {
    color: $color-placeholder;
    font-weight: $weight-normal;
  }
}

input[type="date"] {
  &::-webkit-datetime-edit-month-field {
    color: $color-placeholder;
    font-weight: $weight-normal;
  }
  &::-webkit-datetime-edit-day-field {
    color: $color-placeholder;
    font-weight: $weight-normal;
  }
  &::-webkit-datetime-edit-year-field {
    color: $color-placeholder;
    font-weight: $weight-normal;
  }
  &::-webkit-datetime-edit-text {
    color: $color-placeholder;
    font-weight: $weight-normal;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    min-height: 46px;
  }
  /*&::after{
        color: #fff;
        content: attr(placeholder);
    }*/
}

fieldset {
  padding: 0;
  border: 0;
}

::-webkit-datetime-edit-month-field {
  color: $color-placeholder;
  font-weight: $weight-normal;
  &:focus {
    text-shadow: 0 0 0 $color-white;
    color: $color-white;
  }
}
::-webkit-datetime-edit-day-field {
  color: $color-placeholder;
  font-weight: $weight-normal;
  &:focus {
    text-shadow: 0 0 0 $color-white;
    color: $color-white;
  }
}
::-webkit-datetime-edit-year-field {
  color: $color-placeholder;
  font-weight: $weight-normal;
  &:focus {
    text-shadow: 0 0 0 $color-white;
    color: $color-white;
  }
}
::-webkit-datetime-edit-text {
  color: $color-placeholder;
  font-weight: $weight-normal;
}
