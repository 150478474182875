$text-color: #b3b8d4;
$secondary-text-color: #dee2ec;

$bg-color: #0c1e35;
$secondary-bg-color: #0b1a2c;

$border-color: rgba(#535d7d, 0.3);

$sidebar-header-height: 64px;
$sidebar-footer-height: 64px;

.layout {
  z-index: 1;
  .header {
    box-shadow: 1px 1px 4px #9aa0b9;
    display: flex;
    align-items: center;
    padding: 20px;
  }
  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .footer {
    text-align: center;
    margin-top: auto;
    margin-bottom: 20px;
    padding: 20px;
  }
  &.rtl {
    .header {
      box-shadow: -1px 1px 4px #9aa0b9;
    }
  }
}

.sidebar {
  color: $text-color;
  overflow-x: hidden !important;
  position: relative;
  background-color: $bg-color;

  .image-wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.2;
    z-index: 1;
    display: none;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &.has-bg-image .image-wrapper {
    display: block;
  }

  .sidebar-layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    .sidebar-header {
      height: $sidebar-header-height;
      min-height: $sidebar-header-height;
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-bottom: 1px solid $border-color;
      > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .sidebar-content {
      flex-grow: 1;
      padding: 10px 0;
    }
    .sidebar-footer {
      height: $sidebar-footer-height;
      min-height: $sidebar-footer-height;
      display: flex;
      align-items: center;
      border-top: 1px solid $border-color;
      padding: 0 20px;
      > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }
}

.layout {
  .sidebar {
    .menu {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
      .menu-item {
        a {
          display: flex;
          align-items: center;
          height: 50px;
          padding: 0 20px;
          color: $text-color;

          .menu-icon {
            font-size: 1.2rem;
            width: 35px;
            min-width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            display: inline-block;
            margin-right: 10px;
            border-radius: 2px;
            transition: color 0.3s;
            i {
              display: inline-block;
            }
          }

          .menu-title {
            font-size: 0.9rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-grow: 1;
            transition: color 0.3s;
          }
          .menu-prefix,
          .menu-suffix {
            display: inline-block;
            padding: 5px;
            opacity: 1;
            transition: opacity 0.3s;
          }
          &:hover {
            .menu-title {
              color: $secondary-text-color;
            }
            .menu-icon {
              color: $secondary-text-color;
              i {
                animation: swing ease-in-out 0.5s 1 alternate;
              }
            }
            &::after {
              border-color: $secondary-text-color !important;
            }
          }
        }

        &.sub-menu {
          position: relative;
          > a {
            &::after {
              content: "";
              transition: transform 0.3s;
              border-right: 2px solid currentcolor;
              border-bottom: 2px solid currentcolor;
              width: 5px;
              height: 5px;
              transform: rotate(-45deg);
            }
          }

          > .sub-menu-list {
            padding-left: 20px;
            display: none;
            overflow: hidden;
            z-index: 999;
          }
          &.open {
            > a {
              &::after {
                transform: rotate(45deg);
              }
            }
          }
        }

        &.active {
          > a {
            .menu-title {
              color: $secondary-text-color;
            }
            &::after {
              border-color: $secondary-text-color;
            }
            .menu-icon {
              color: $secondary-text-color;
            }
          }
        }
      }
      > ul > .sub-menu > .sub-menu-list {
        background-color: $secondary-bg-color;
      }

      &.icon-shape-circle,
      &.icon-shape-rounded,
      &.icon-shape-square {
        .menu-item a .menu-icon {
          background-color: $secondary-bg-color;
        }
      }

      &.icon-shape-circle .menu-item a .menu-icon {
        border-radius: 50%;
      }
      &.icon-shape-rounded .menu-item a .menu-icon {
        border-radius: 4px;
      }
      &.icon-shape-square .menu-item a .menu-icon {
        border-radius: 0;
      }
    }

    &:not(.collapsed) {
      .menu > ul {
        > .menu-item {
          &.sub-menu {
            > .sub-menu-list {
              visibility: visible !important;
              position: static !important;
              transform: translate(0, 0) !important;
            }
          }
        }
      }
    }

    &.collapsed {
      .menu > ul {
        > .menu-item {
          > a {
            .menu-prefix,
            .menu-suffix {
              opacity: 0;
            }
          }
          &.sub-menu {
            > a {
              &::after {
                content: "";
                width: 5px;
                height: 5px;
                background-color: currentcolor;
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                right: 10px;
                top: 50%;
                border: none;
                transform: translateY(-50%);
              }
              &:hover {
                &::after {
                  background-color: $secondary-text-color;
                }
              }
            }
            > .sub-menu-list {
              transition: none !important;
              width: 200px;
              margin-left: 3px !important;
              border-radius: 4px;
              display: block !important;
            }
          }
          &.active {
            > a {
              &::after {
                background-color: $secondary-text-color;
              }
            }
          }
        }
      }
    }
    &.has-bg-image {
      .menu {
        &.icon-shape-circle,
        &.icon-shape-rounded,
        &.icon-shape-square {
          .menu-item a .menu-icon {
            background-color: rgba($secondary-bg-color, 0.6);
          }
        }
      }
      &:not(.collapsed) {
        .menu {
          > ul > .sub-menu > .sub-menu-list {
            background-color: rgba($secondary-bg-color, 0.6);
          }
        }
      }
    }
  }

  &.rtl {
    .sidebar {
      .menu {
        .menu-item {
          a {
            .menu-icon {
              margin-left: 10px;
              margin-right: 0;
            }
          }

          &.sub-menu {
            > a {
              &::after {
                transform: rotate(135deg);
              }
            }

            > .sub-menu-list {
              padding-left: 0;
              padding-right: 20px;
            }
            &.open {
              > a {
                &::after {
                  transform: rotate(45deg);
                }
              }
            }
          }
        }
      }

      &.collapsed {
        .menu > ul {
          > .menu-item {
            &.sub-menu {
              a::after {
                right: auto;
                left: 10px;
              }

              > .sub-menu-list {
                margin-left: -3px !important;
              }
            }
          }
        }
      }
    }
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  color: #212529;
}

a {
  text-decoration: none;
}

@media (max-width: 992px) {
  #btn-collapse {
    display: none;
  }
}

.yc_sidebar_wrapper {
  position: fixed;
  z-index: 14;
  height: calc(100% - 80px);
  line-height: 1.5;
  top: 90px;
  left: 30px;
  &:before {
    box-sizing: border-box;
  }
  &:after {
    box-sizing: border-box;
  }
}

.yc_sidebar_container {
  width: 280px;
  border-radius: 0.35rem;
  height: calc(100% - 30px);
  z-index: 11;
  background: #fff;
  position: relative;
  flex: 0 0 280px;
  overflow: hidden;
  transition: all 0.2s;
  box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, 0.3),
    0 0.9375rem 1.40625rem rgba(31, 10, 6, 0.3),
    0 0.25rem 0.53125rem rgba(31, 10, 6, 0.5),
    0 0.125rem 0.1875rem rgba(31, 10, 6, 0.3);
}

.yc_sidebar_logo {
  width: 100%;
  min-width: 45px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-bottom: rgba(0, 0, 0, 0.04) solid 1px;
  background: rgba(0, 0, 0, 0.01);
  & > .logo {
    background: url("../../images/min.png") 50% 50% no-repeat;
    height: 50px;
    width: 100px;
    background-size: contain;
  }
}

.yc_sidebar_hamburger {
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  margin-left: auto;
  & > i {
    font-size: 24px;
  }
}

.yc_icon {
  padding-right: 4px;
  &_group {
    text-align: center;
    font-size: 15px;
    line-height: 34px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    opacity: 0.45;
    transition: color 0.2s;

    transition: transform 300ms;
    left: auto;
    right: 5px;
  }
}

.yc_menu_item {
  display: flex;
  align-items: center;
  width: 100%;
  .yc_icon {
    width: 25px;
  }
}

.vertical-nav-menu {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 60px;
  &::-webkit-scrollbar {
    width: $space-unit;
  }
  &::-webkit-scrollbar-track {
    background: linear-gradient($grey-color-light, $color-xlight);
    box-shadow: $shadow;
    border-radius: 0 0 15px;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient($color-black, #555);
    box-shadow: $shadow;
    border-radius: 15px;
    cursor: pointer;
    &:hover {
      box-shadow: $shadow-hover;
    }
  }
  li a {
    display: block;
    line-height: 2.5rem;
    height: 2.5rem;
    padding: 0 1.5rem 0 1.95rem;
    position: relative;
    border-radius: 0.35rem;
    color: #6c757d;
    white-space: nowrap;
    transition: all 0.2s;
    margin: 0.1rem 0;
    font-size: $font-size-deci;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover {
      color: $color-primary;
      font-weight: $weight-bold;
    }
    &.active {
      color: $color-primary;
      font-weight: $weight-bold;
    }
  }
  ul {
    transition: padding 300ms;
    padding: 0 0.5rem;
    position: relative;
    list-style: none;
  }
}

.vertical-nav-menu .mm-collapse:not(.mm-show) {
  display: none;
}
.yc_icon_admin {
  font-size: 20px;
}

.yc_container_main {
  background-color: #f0f0f0;
  min-height: 100vh;
  padding: 0 10px 0 calc((30px) + 30px + 210px);
}
