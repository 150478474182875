.box {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__login {
    max-width: 45rem;
  }
  &__search {
    max-width: 60rem;
  }
  &__file {
    max-width: 90%;
  }
  &__login,
  &__search,
  &__file {
    width: 100%;
    padding: $space-unit;
    .card {
      border-radius: $border-radius;
      box-shadow: $shadow;
      background-color: #dddee1;
      padding: $space-base;
    }
  }
}

.article {
  &--item {
    cursor: pointer;
    display: block !important;
    transition: color 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    margin-bottom: 0 !important;
    padding: calc($space-deci / 2) !important;
    &:hover {
      background-color: rgba($color: $color-primary, $alpha: 0.5);
      color: $color-white;
      .ant-typography {
        color: $color-white;
      }
    }
    .date {
      font-size: $font-size-centi;
      padding: 0 $space-deci;
      font-style: italic;
    }
  }
}
.search {
  &--title {
    &.ant-typography {
      text-align: center;
      width: 100%;
      display: inline-block;
      margin-top: $space-deca;
      font-weight: bolder;
      background: -webkit-linear-gradient($color-primary, $color-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &--input {
    border-radius: 15px !important;
    max-width: 100%;
    margin: auto;
    width: 100%;
    line-height: 32px !important;
    &:hover {
      box-shadow: $shadow;
    }
    &-container {
      display: flex;
      justify-content: center;
      &:focus {
        box-shadow: $shadow-hover;
      }
      & > div {
        &:first-child {
          max-width: 540px;
        }
      }
    }
  }
}
