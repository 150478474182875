.table {
  .ant-table-row {
    cursor: pointer;
  }
  &.red {
    .ant-table-thead {
      background: $color-danger;
    }
  }
  .ant-table-thead {
    background: $color-primary;
    border: none;
    box-shadow: $shadow !important;
    .ant-table-cell {
      background: transparent;
      font-family: $weight-bold;
      color: #fff;
      &::before {
        width: 0px !important;
      }
    }
  }
}
