.splash {
  position: fixed;
  z-index: 1000;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  background-color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: auto;
    object-fit: cover;
  }
  &--body {
    overflow: hidden;
    max-height: 100vh;
    max-width: 100%;
  }
}
