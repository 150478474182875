.step {
  &--title {
    flex: 1;
  }
  &--cta {
    display: flex;
    justify-content: flex-end;
  }
  &--container {
    align-items: baseline;
    margin-bottom: calc($space-unit / 2);
    .title {
      background-color: $color-success;
      color: $color-white;
      text-align: center;
      padding: $space-unit $space-base;
      border-radius: $space-base $space-base 0 0;
      font-weight: $weight-bold;
      font-size: $font-size-deci;
      width: max-content;
      &.create {
        background-color: $color-danger;
      }
      &.update {
        background-color: $color-primary;
      }
    }
    &.border {
      border-bottom: solid 0.001rem $color-grey-deci;
    }
  }
}
.btn--round {
  &.ant-btn {
    border-radius: $space-base $space-base 0 0;
  }
}

.btn--choice {
  &.ant-btn {
    min-height: 32px;
    height: auto;
    white-space: normal;
  }
}
