// *----------------------------------*\
//  Flexbox SASS mixins
//  The spec: http://www.w3.org/TR/css3-flexbox
// *----------------------------------*/

// Flexbox display
@mixin flexbox {
  display: flex;
}

// Inline flex display
@mixin inline-flex {
  display: inline-flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($fg: 1, $fs: 0, $fb: auto) {
  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so lets grab the
  // first item in the list and just return that.
  @if type-of($fg) == "list" {
    $fg-boxflex: nth($fg, 1);
  }

  flex: $fg $fs $fb;
}

// Flex Flow Direction
// - applies to: flex containers
// row (default) | row-reverse | column | column-reverse
@mixin flex-direction($direction: row) {
  @if $direction == row-reverse {
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  } @else if $direction == column {
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  } @else if $direction == column-reverse {
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  } @else {
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($value: nowrap) {
  flex-wrap: $value;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow(
  $values: (
    row nowrap,
  )
) {
  flex-flow: $values;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($int: 0) {
  order: $int;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($int: 1) {
  flex-grow: $int;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($int: 0) {
  flex-shrink: $int;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($value: auto) {
  flex-basis: $value;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
  } @else {
    -webkit-box-pack: $value;
  }
  -webkit-justify-content: $value;
  justify-content: $value;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
  } @else {
    -webkit-box-align: $value;
  }
  -webkit-align-items: $value;
  align-items: $value;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($value: auto) {
  align-self: $value;
}
